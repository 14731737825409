// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Contains the default environment settings
 */
export const environment = {
  production: true,
  version: {
    code: '1.0',
    build: '15'
  },
  title: 'Ship Collab Snaglist',
  isMultiInstance: false,
  multiInstance: {},
  storage: {
    prefix: 'shipyard40_dream_slm_staging__'
  },
  auth: {
    persistent: true,
    baseUrl: 'https://shipcollab-snag-list.staging.netseven.it/oauth',
    clientId: '2',
    clientSecret: 'WUAdwDVixJuyTn22FdcDISmXOe9gwvd75LTc1PbF',
    scope: ''
  },
  data: {
    baseUrl: 'https://shipcollab-snag-list.staging.netseven.it/api',
    apiVersion: 'v1'
  },
  features: {
    task: {
      filterStatuses: [
        {
          key: 'submitted',
          name: 'Sent'
        },
        // {
        //   key: 'denied',
        //   name: 'Declined'
        // },
        // {
        //   key: 'accepted',
        //   name: 'Accepted'
        // },
        {
          key: 'in progress',
          name: 'In progress'
        },
        {
          key: 'completed',
          name: 'Completed'
        },
        // {
        //   key: 'monitored',
        //   name: 'Monitored'
        // }
      ],
      flowStatuses: [
        // Primary
        {
          key: 'draft',
          label: 'Draft',
          isOpen: true,
          nextKeys: ['draft', 'submitted'],
          previousKeys: []
        },
        {
          key: 'submitted',
          label: 'Sent',
          isOpen: true,
          nextKeys: ['submitted', 'accepted', 'denied'],
          previousKeys: ['draft']
        },
        {
          key: 'accepted',
          label: 'Accepted',
          isOpen: true,
          nextKeys: ['accepted', 'in progress'],
          previousKeys: ['submitted']
        },
        {
          key: 'denied',
          label: 'Declined',
          isOpen: true,
          nextKeys: ['denied'],
          previousKeys: ['submitted']
        },
        {
          key: 'in progress',
          label: 'In progress',
          isOpen: true,
          nextKeys: ['in progress', 'completed'],
          previousKeys: ['accepted', 'monitored']
        },
        {
          key: 'completed',
          label: 'Completed',
          isOpen: true,
          nextKeys: ['completed', 'in progress', 'monitored'],
          previousKeys: ['in progress']
        },
        {
          key: 'monitored',
          label: 'Monitored',
          isOpen: true,
          nextKeys: ['in progress'],
          previousKeys: ['completed']
        },
        // Remark
        {
          key: 'open',
          label: 'Open',
          isOpen: true,
          nextKeys: ['open', 'no_actions', 'local_repaint', 'total_repaint'],
          previousKeys: []
        },
        {
          key: 'no_actions',
          label: 'No actions',
          isOpen: true,
          nextKeys: ['no_actions', 'closed-no_actions'],
          previousKeys: []
        },
        {
          key: 'closed-no_actions',
          label: 'Closed remark',
          isOpen: false,
          nextKeys: [],
          previousKeys: []
        },
        {
          key: 'local_repaint',
          label: 'Local repaint',
          isOpen: true,
          nextKeys: ['local_repaint', 'closed-local_repaint'],
          previousKeys: []
        },
        {
          key: 'closed-local_repaint',
          label: 'Closed remark',
          isOpen: false,
          nextKeys: [],
          previousKeys: []
        },
        {
          key: 'total_repaint',
          label: 'Total repaint',
          isOpen: true,
          nextKeys: ['total_repaint', 'closed-total_repaint'],
          previousKeys: []
        },
        {
          key: 'closed-total_repaint',
          label: 'Closed remark',
          isOpen: false,
          nextKeys: [],
          previousKeys: []
        },
      ],
      hasOfflineDraftsFuncionality: true,
      hasPreviuosStatusFuncionality: false,
      create: {
        useSuggestions: true,
        canChangeStatus: false,
        showZone: false,
        canSetZone: false,
        canChangeZone: false,
        canChangeType: true,
        canViewDoc: false,
        showPrivacy: false,
        showResolutionDate: false,
        showResolutorInCharge: false,
        showDocumentReference: false,
        canChangePrivacy: false,
        canChangeLocation: false
      },
      edit: {
        useSuggestions: true,
        canChangeStatus: true,
        showZone: false,
        canSetZone: false,
        canChangeZone: false,
        canChangeType: false,
        canViewDoc: false,
        showPrivacy: false,
        showResolutionDate: true,
        showResolutorInCharge: true,
        showDocumentReference: true,
        canChangePrivacy: false,
        canChangeLocation: false,
        canBeDeleted: true
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
