import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ModalController, NavParams, IonSlides, Platform } from '@ionic/angular';
import { DataService } from 'src/app/services/data/data.service';
import { Swiper } from 'swiper';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-modal-zoom',
  templateUrl: './modal-zoom.component.html',
  styleUrls: ['./modal-zoom.component.scss'],
})
export class ModalZoomComponent implements OnInit {
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  public itemType: string;
  public item: any;
  public image: string;

  config: any = {
    zoom: {
      minRatio: 0.2,
      maxRatio: 10
    }
  };

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private dataService: DataService
  ) {
    this.itemType = this.navParams.get('itemType');
    this.item = this.navParams.get('items');
  }

  async ngOnInit() { }

  async ionViewDidEnter() {
    this.image = await this.getImage(this.item);
  }

  dismiss() {
    console.log('dismiss');
    this.modalController.dismiss(
      {
        confirmed: false
      }
    );
  }

  zoom(zoomIn: boolean) {
    const zoom = this.swiperRef.nativeElement.swiper.zoom;
    if (zoomIn) {
      zoom.in();
    }
    else {
      zoom.out();
    }
  }

  private async getImage(item: any) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        if (item && ('base64' === this.itemType)) {
          resolve(item);
        }
        if (item && ('document' === this.itemType)) {
          if (('string' === typeof item.id) && (item.id.lastIndexOf('image_', 0) === 0)) {
            resolve('data:image/png;base64,' + item.attributes.base64);
          }
          else {
            this.dataService.getImage(item.id).pipe(take(1)).subscribe((image) => {
              resolve('data:image/png;base64,' + image.base64);
            });
          }
        }
        else {
          resolve('');
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}
