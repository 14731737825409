import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavParams, IonSlides, Platform } from '@ionic/angular';
import { CanvasComponent } from '../canvas/canvas.component';
import { DataService } from 'src/app/services/data/data.service';
import { take } from 'rxjs/operators';
import { Utilities } from 'src/app/commons/utilities.class';

@Component({
  selector: 'app-modal-draw',
  templateUrl: './modal-draw.component.html',
  styleUrls: ['./modal-draw.component.scss'],
})
export class ModalDrawComponent implements OnInit {
  @ViewChild('slides') slides: IonSlides;
  @ViewChild(CanvasComponent) appCanvas: CanvasComponent;

  hasImage: boolean;

  public itemType: string;
  public item: any;
  // public index: number;

  public platformWidth: number;
  public platformHeight: number;

  public image: string;
  public toolType: string;
  public lineColor: string;
  public canvasHasUndo: boolean;
  public canvasDpr: number;
  public canvasWidth: number;
  public canvasHeight: number;

  public imageWidth: number;
  public imageHeight: number;

  public fabColor = 'primary';
  public fabIcon = 'settings';

  @ViewChild('canvasWrapper', { read: ElementRef, static: true }) private canvasWrapper: ElementRef;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private dataService: DataService,
    platform: Platform
  ) {
    this.hasImage = false;
    this.toolType = 'pencil';
    this.lineColor = '#3880ff';
    this.canvasDpr = 1;


    this.itemType = this.navParams.get('itemType');
    this.item = this.navParams.get('items');
    platform.ready().then((readySource) => {
      // console.log('readySource', readySource);
      this.platformWidth = platform.width();
      this.platformHeight = platform.height();
    });
  }

  async ngOnInit() { }

  async ionViewDidEnter() {
    this.image = await this.getImage(this.item);
    await Utilities.waitFor(100);
    if (this.image) {
      this.hasImage = true;
    }

    const self = this;
    await this.getImageMeta(this.image, (width, height) => {
      // console.log(width + 'px ' + height + 'px');
      self.imageWidth = width;
      self.imageHeight = height;
    });

    if (this.canvasWrapper) {
      // eslint-disable-next-line max-len
      this.canvasHeight = (this.platformHeight) ? this.platformHeight : this.canvasWrapper.nativeElement.offsetHeight; // - this.safeAreaInsetBottom;
      this.canvasWidth = (this.platformWidth) ? this.platformWidth : this.canvasWrapper.nativeElement.offsetWidth;

      const imageRatio = this.imageWidth / this.imageHeight;
      const canvasRatio = this.canvasWidth / this.canvasHeight;

      if (imageRatio >= canvasRatio) {
        this.canvasDpr = this.imageWidth / this.canvasWidth;
        this.canvasHeight = this.canvasWidth / imageRatio;
      }
      else {
        this.canvasDpr = this.imageHeight / this.canvasHeight;
        this.canvasWidth = this.canvasHeight / imageRatio;
      }
      this.canvasDpr = 1;
      setTimeout(() => {
        if (this.appCanvas) {
          this.appCanvas.initCanvas();
          this.appCanvas.restart();
        }
      }, 100);
    }
  }

  dismiss() {
    this.modalController.dismiss(
      {
        confirmed: false
      }
    );
  }

  confirm() {
    this.modalController.dismiss(
      {
        confirmed: true,
        image: this.appCanvas.getCurrent()
      }
    );
  }

  async getImageMeta(url, callback) {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          callback(img.width, img.height);
          resolve(true);
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  canvasUndo() {
    // this.appCanvas.undo();
    this.appCanvas.restart();
  }

  onHasUndo(length) {
    this.canvasHasUndo = (length);
  }

  skipDraw() {
  }

  lineColorChange($event) {
    this.lineColor = $event.detail.value;
    switch (this.lineColor) {
      case '#f04141':
        this.fabColor = 'danger';
        break;

      case '#10dc60':
        this.fabColor = 'success';
        break;

      case '#f4f5f8':
        this.fabColor = 'light';
        break;

      case '#222428':
        this.fabColor = 'dark';
        break;

      case '#3880ff':
      default:
        this.fabColor = 'primary';
        break;
    }
  }

  toolTypeChange($event) {
    this.toolType = $event.detail.value;
    switch (this.toolType) {
      case 'pencil':
        this.fabIcon = 'create';
        break;

      case 'rect':
        this.fabIcon = 'square-outline';
        break;

      case 'circle':
        this.fabIcon = 'radio-button-off';
        break;

      default:
        this.fabIcon = 'settings';
        break;
    }
  }

  private async getImage(item: any) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        if (item && ('base64' === this.itemType)) {
          resolve(item);
        }
        if (item && ('document' === this.itemType)) {
          // console.log('item', item);
          if (('string' === typeof item.id) && (item.id.lastIndexOf('image_', 0) === 0)) {
            resolve('data:image/png;base64,' + item.attributes.base64);
          }
          else {
            this.dataService.getImage(item.id).pipe(take(1)).subscribe((image) => {
              resolve('data:image/png;base64,' + image.base64);
            });
          }
        }
        else {
          resolve('');
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}
